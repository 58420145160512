<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14.167" height="7.083" viewBox="0 0 14.167 7.083" class="arrow" v-if="!showText" >
    <g id="arrow_right-24px_1_" data-name="arrow_right-24px (1)" transform="translate(10.27 2.666) rotate(90)">
      <path id="Path_1171" data-name="Path 1171" d="M0,14.167,7.083,7.083,0,0Z" transform="translate(-2.666 -3.896)" fill="#fff"/>
      <path id="Path_1172" data-name="Path 1172" d="M0,6.374V0H1.751V6.374Z" transform="translate(0 0)" fill="none"/>
    </g>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" width="14.167" height="7.083" viewBox="0 0 14.167 7.083" v-else class="arrow">
    <g id="arrow_right-24px_1_" data-name="arrow_right-24px (1)" transform="translate(5.838 5.148) rotate(-90)">
      <path id="Path_1171" data-name="Path 1171" d="M0,14.167,7.083,7.083,0,0Z" transform="translate(-1.936 -5.838)" fill="#fff"/>
      <path id="Path_1172" data-name="Path 1172" d="M0,2.491V0H3.212V2.491Z" transform="translate(0 0)" fill="none"/>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'OpenButton',
  props: {
    showText: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

// .arrow:hover {
//   opacity: 0.5;
//   cursor: pointer;
// }

</style>

<template>
  <li class="review">
    <section class="profile">
      <img 
        v-if="review.client.img" 
        :src="review.client.img" 
        alt="" class="profile--image"
      >
      <section class="profile--info">
        <p class="profile--name">
          {{ review.client.name }} {{  review.client.surname }}
        </p>
        <p class="profile--location">
          {{ review.client.address }}
        </p>
        <div class="profile--media">
          <Instagram 
            color="#fff" 
            :href="review.client.instagram" 
            v-if="review.client.instagram"
            class="profile--icon"
          />
          <VK 
            color="#fff" 
            :href="review.client.vk" 
            v-if="review.client.vk"
            class="profile--icon"
          />
          <Odnoklassniki 
            color="#fff" 
            :href="review.client.odnoklassniki" 
            v-if="review.client.odnoklassniki"
            class="profile--icon"
          />
          <Facebook 
            color="#fff" 
            :href="review.client.facebook" 
            v-if="review.client.facebook"
            class="profile--icon"
          />
          <Twitter 
            color="#fff" 
            :href="review.client.twitter" 
            v-if="review.client.twitter"
          />
        </div>
      </section>
    </section>
    <section class="review--content">
      <!-- <ul class="review--rating"> -->
        <!-- <Star v-for="num in review.rating" :key="num" /> -->
        <!-- :color="color" -->
        <!-- <Star 
          v-for="left in (5 - review.rating)" 
          :left="left" 
          :key="left" 
        /> -->
        <!-- :color="color"  -->

      <!-- </ul> -->
        <p v-html="slicedComment.replace('Verdana', 'Montserrat')">
          {{ slicedComment}}
        </p>
        <!-- <p class="review--text"  v-if="isHidden">
          {{ `${review.comment.slice(0, 220)}...` }}
        </p>
        <p class="review--text" v-else>
          {{ review.comment }}
        </p> -->
        <button 
          v-if="noButton" 
          class="review--button" 
          @click="isHidden = !isHidden"
        >
          <span v-if="isHidden" >Читать полностью</span>
          <OpenButton :showText="!isHidden" />
        </button>
        <span v-if="review.answer" class="gold">+ ответ администрации</span>
        <p class="review--answer" v-if="!isHidden">
          {{ review.answer }}
        </p>
    </section>
  </li>
</template>

<script>
// import Star from '../Tooltip/Star'
import Instagram from '@/components/common/MediaIcons/Instagram'
import VK from '@/components/common/MediaIcons/VK'
import Odnoklassniki from '@/components/common/MediaIcons/Odnoklassniki'
import Facebook from '@/components/common/MediaIcons/Facebook'
import Twitter from '@/components/common/MediaIcons/Twitter'
import OpenButton from '@/components/ui/buttons/OpenButton'


export default {
  name: 'Review',
  components: {
    // Star,
    Instagram,
    VK,
    Odnoklassniki,
    Facebook,
    Twitter,
    OpenButton
  },
  props: {
    review: {
      type: Object,
      required: true
    },
    // color: {
    //   type: String,
    //   required: true
    // }
  },
  data() {
    return {
      isHidden: this.review.comment.length - 108 > 220,
      noButton: this.review.comment.length - 108 > 220
    }
  },
  computed: {
    slicedComment() {
      return this.isHidden ? this.review.comment.slice(0, 220) :
             this.review.comment
    }
  }
}
</script>

<style lang="scss" scoped>

.review {
  width: 100px;
  height: 100px;
  padding-top: 21px;
  padding-bottom: 15px;
  border-top: 1px solid #8F8F8F;
  border-bottom: 1px solid #8F8F8F;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  
  
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
  
  &--rating {
    display: flex;
    width: 110px;
    margin-bottom: 24px;
  }

  &--content {
    flex: 1;
    height: 100%;    
    display: flex;
    flex-direction: column;
  }


  &--button {
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    text-transform: lowercase;
    align-self: flex-end;
    margin-top: 11px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  &--answer {
    font-size: 13px;
    font-style: italic;
    margin-top: 10px;
  }
}

.profile {
  flex: 1;
  display: flex;
  height: 100%;
  align-items: center;
  margin-top: 3px;

  @media screen and (max-width: 640px) {
    margin-bottom: 20px;
  }

  &--media {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  &--location {
    margin-top: 5px;
  }

  &--info {
    display: flex;
    flex-direction: column;
  }

  &--image {
    margin-right: 35px;
  }

  &--icon {
    margin-right: 10px;
  }
}

.gold {
  color: #D5B94B;
  margin-top: 10px;
}
</style>
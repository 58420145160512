<template>
  <a :href="href">
    <svg xmlns="http://www.w3.org/2000/svg" width="24.729" height="24.729" viewBox="0 0 24.729 24.729" class="media-icon" :fill="color">
      <path id="iconfinder_Colored_Facebook3_svg_5365678" d="M21.707,0a3.023,3.023,0,0,1,3.021,3.021V21.707a3.023,3.023,0,0,1-3.021,3.021H16.565V15.411h3.217l.612-3.99H16.565V8.831a2,2,0,0,1,2.25-2.156h1.741v-3.4a21.231,21.231,0,0,0-3.09-.27c-3.153,0-5.214,1.911-5.214,5.371v3.041H8.746v3.99h3.505v9.317H3.021A3.023,3.023,0,0,1,0,21.707V3.021A3.023,3.023,0,0,1,3.021,0H21.707Z"  fill-rule="evenodd"/>
    </svg>

  </a>
</template>

<script>
export default {
  name: 'Facebook',
  props: ['href', 'color']

}
</script>

<style scoped lang="scss">
</style>
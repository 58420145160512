<template>
  <div class="reviews-wrapper">
    <article class="reviews">
      <p class="reviews--title">Отзывы</p>
      <ul class="reviews--list">
        <Review 
          v-for="review in reviews" 
          :review="review"
          :key="review.id" 
        />
        <!-- :color="colors[review.rating]" -->
      </ul>
    </article>
  </div>
</template>

<script>
import Review from './Review'
import API from '@/API'


export default {
  name: 'Reviews',
  components: {
    Review,
  },
  data() {
    return {
      reviews: [],
      colors: {
        1: '#EE2B26',
        2: '#FE6F30',
        3: '#26D9EE',
        4: '#E7EE26',
        5: '#26EE3A',
      },
    }
  },
  async created() {
    this.reviews = await API.getReviews();
  }
}
</script>

<style scoped lang="scss">

.reviews-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.reviews {
  width: 60%;

  &--title {
    display: inline-block;
    text-transform: uppercase;
    font-family: 'Proxima Soft';
    font-size: 25px;
    line-height: 140%;
    border-bottom: 2px solid #fff;
  }

  &--list {
    margin-top: 10px;
  }
}
</style>
<template>
  <a :href="href">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" class="media-icon" :fill="color">
      <path id="iconfinder_Circled_Twitter_svg_5279123" d="M12.5,0A12.5,12.5,0,1,1,0,12.5,12.506,12.506,0,0,1,12.5,0ZM10.3,19.148a8.5,8.5,0,0,0,8.556-8.556c0-.131,0-.262-.006-.387a6.16,6.16,0,0,0,1.5-1.559,6.1,6.1,0,0,1-1.727.474,3.005,3.005,0,0,0,1.322-1.665,6.09,6.09,0,0,1-1.908.73,3.009,3.009,0,0,0-5.2,2.058,2.756,2.756,0,0,0,.081.686,8.53,8.53,0,0,1-6.2-3.143,3.005,3.005,0,0,0,.935,4.01,2.954,2.954,0,0,1-1.359-.374v.037a3.01,3.01,0,0,0,2.413,2.95,3,3,0,0,1-.792.106,2.868,2.868,0,0,1-.567-.056,3,3,0,0,0,2.806,2.089,6.044,6.044,0,0,1-3.735,1.285,5.473,5.473,0,0,1-.717-.044,8.378,8.378,0,0,0,4.6,1.359Z" fill-rule="evenodd"/>
    </svg>

  </a>
</template>

<script>
export default {
  name: 'Twitter',
  props: ['href', 'color']

}
</script>

<style scoped lang="scss">
</style>
<template>
  <a :href="href">
    <svg id="iconfinder_SocialMedia_Instagram-Outline_2959748" xmlns="http://www.w3.org/2000/svg" width="25.004" height="25.004" viewBox="0 0 25.004 25.004" class="media-icon" :fill="color">
      <path id="Path_1263" data-name="Path 1263" d="M32.35,17.041a5.613,5.613,0,0,1,5.613,5.613v9.7a5.613,5.613,0,0,1-5.613,5.613h-9.7a5.613,5.613,0,0,1-5.613-5.613v-9.7a5.613,5.613,0,0,1,5.613-5.613h9.7m0-2.041h-9.7A7.677,7.677,0,0,0,15,22.654v9.7A7.677,7.677,0,0,0,22.654,40h9.7A7.677,7.677,0,0,0,40,32.35v-9.7A7.677,7.677,0,0,0,32.35,15Z" transform="translate(-15 -15)"/>
      <path id="Path_1264" data-name="Path 1264" d="M85.872,32a1.462,1.462,0,1,0,1.462,1.462A1.462,1.462,0,0,0,85.872,32Z" transform="translate(-66.7 -27.663)"/>
      <path id="Path_1265" data-name="Path 1265" d="M45.179,40.511a4.511,4.511,0,1,1-4.508,4.509,4.511,4.511,0,0,1,4.508-4.509m0-2.041a6.552,6.552,0,1,0,4.64,1.914A6.552,6.552,0,0,0,45.179,38.47Z" transform="translate(-32.608 -32.482)"/>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'Instagram',
  props: ['href', 'color']

}
</script>

<style scoped lang="scss">



</style>
<template>
  <a :href="href">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" class="media-icon" :fill="color">
      <g id="iconfinder_social-37_1591891" transform="translate(-5 -5.001)">
        <circle id="Ellipse_433" data-name="Ellipse 433" cx="12.5" cy="12.5" r="12.5" transform="translate(5 5.001)"/>
        <g id="Group_261" data-name="Group 261" transform="translate(13.224 9.992)">
          <path id="Path_1322" data-name="Path 1322" d="M40.833,53.925a6.921,6.921,0,0,1-1.615-.587c-.182-.094-.361-.195-.535-.3a1.09,1.09,0,0,1,1.134-1.861,4.843,4.843,0,0,0,1.655.646,4.667,4.667,0,0,0,3.288-.585,1.286,1.286,0,0,1,.668-.218,1.093,1.093,0,0,1,1.046.771,1.08,1.08,0,0,1-.445,1.228,6.8,6.8,0,0,1-2.067.882l-.107.028c.03.032.053.058.078.083q.982.982,1.964,1.964a1.091,1.091,0,1,1-1.523,1.562q-.979-.974-1.952-1.951c-.023-.023-.045-.048-.1-.1a.472.472,0,0,1-.057.1q-.977.981-1.957,1.958a1.09,1.09,0,0,1-1.593-1.486c.052-.059.108-.114.164-.169l1.875-1.875Z" transform="translate(-38.163 -43.44)" fill="#1c1c1c" fill-rule="evenodd"/>
          <path id="Path_1323" data-name="Path 1323" d="M43.181,29.567a3.723,3.723,0,1,1-3.725,3.73A3.73,3.73,0,0,1,43.181,29.567Zm1.539,3.727a1.541,1.541,0,1,0-1.57,1.538A1.539,1.539,0,0,0,44.72,33.294Z" transform="translate(-38.999 -29.567)" fill="#1c1c1c" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'Odnoklassniki',
  props: ['href', 'color']

}
</script>

<style scoped lang="scss">
</style>
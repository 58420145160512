<template>
  <a :href="href">
    <svg id="iconfinder_23_Media_social_website_vk_2657557" xmlns="http://www.w3.org/2000/svg" width="25.015" height="14" viewBox="0 0 25.015 14" class="media-icon" :fill="color">
      <path id="Path_1268" data-name="Path 1268" d="M137.131,172.99a7.347,7.347,0,0,0-2.714-.246,17.2,17.2,0,0,0-2.7.136,16.545,16.545,0,0,0-1.591,2.948c-.864,1.629-1.912,3.208-2.579,3.183-.876,0-.666-2.677-.6-4.662.012-1.3-.2-1.715-.383-1.826a7.354,7.354,0,0,0-1.715-.629s-3.776-.284-4.306,1.344c.728.1,1.961-.124,1.48,4.922-.037.247-.58,2.32-2.332-.481-.444-.7-2.121-4.909-2.714-4.934-1.938-.037-3.861-.124-4.527.246-.63.37.838,3.257,3.318,7.131,2.01,3.183,4.145,5.65,7.846,6.267a9.138,9.138,0,0,0,3.085-.123c.382-.185-.1-2.924,1.221-3.2.58-.111,2.837,2.863,3.688,3.2a8.3,8.3,0,0,0,2.813.246c1.258-.037,2.689.124,2.825-.74.309-1.888-3.811-4.356-3.923-5.515-.1-.839,1.505-2.381,2.319-3.565,1.011-1.431,2.123-3.158,1.493-3.7Z" transform="translate(-112.3 -171.874)" fill="" fill-rule="evenodd"/>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'VK',
  props: ['href', 'color']

}
</script>

<style scoped lang="scss">
</style>